
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { defineComponent, computed, onMounted, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import moment from "moment";
import { tsXLXS } from "ts-xlsx-export";

export default defineComponent({
  components: {
    Datatable,
  },
  setup() {
    const json_fields = {
      "Complete name": "name",
      City: "city",
      Telephone: "phone.mobile",
      "Telephone 2": {
        field: "phone.landline",
        callback: (value) => {
          return `Landline Phone - ${value}`;
        },
      },
    };

    const json_data = [
      {
        name: "Tony Peña",
        city: "New York",
        country: "United States",
        birthdate: "1978-03-15",
        phone: {
          mobile: "1-541-754-3010",
          landline: "(541) 754-3010",
        },
      },
      {
        name: "Thessaloniki",
        city: "Athens",
        country: "Greece",
        birthdate: "1987-11-23",
        phone: {
          mobile: "+1 855 275 5071",
          landline: "(2741) 2621-244",
        },
      },
    ];
    const tableHeader = ref([
      {
        name: "ORDER CODE",
        key: "order_code",
        sortable: false,
      },
      {
        name: "ORDER TYPE",
        key: "order_type",
        sortable: false,
      },
      {
        name: "DATE/TIME",
        key: "order_time_start",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "STORE",
        key: "order_store",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "CUSTOMER",
        key: "order_customer_full_name",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "RIDER",
        key: "order_rider_full_name",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "STATUS",
        key: "order_status",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "NET PRICE",
        key: "order_net_price",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "",
        key: "",
        sortingField: "status.label",
        sortable: false,
      },
    ]);
    const store = useStore();

    const reportList = computed(() => {
      return store.getters.orderReport;
    });

    const originalConfig = computed(() => {
      return store.state.ReportModule.originalOrderConfig;
    });

    const countOrderFoodComplete = computed(() => {
      let filtered = reportList.value.filter(
        (val) => val.order_type == 1 && val.order_status == "completed"
      );
      return filtered.length || 0;
    });

    const countOrderFoodCancel = computed(() => {
      let filtered = reportList.value.filter(
        (val) => val.order_type == 1 && val.order_status == "cancel"
      );
      return filtered.length || 0;
    });

    const countOrderMessengerComplete = computed(() => {
      let filtered = reportList.value.filter(
        (val) => val.order_type == 2 && val.order_status == "completed"
      );
      return filtered.length || 0;
    });

    const countMessengerFoodCancel = computed(() => {
      let filtered = reportList.value.filter(
        (val) => val.order_type == 2 && val.order_status == "cancel"
      );
      return filtered.length || 0;
    });

    function exportReportToExcel() {
      let data: Record<string, string>[] = [];
      for (let val of reportList.value) {
        const form = {
          "Order code": val.order_code,
          "Order type":
            val.order_type == 1
              ? "Food delivery"
              : val.order_type == 2
              ? "Messenger"
              : "",
          "Date/Time": val.order_time_start,
          Store: val.order_store,
          Customer: val.order_customer_full_name,
          Rider: val.order_rider_full_name,
          Status: val.order_status,
          "Net price": val.order_net_price,
        };
        data.push(form);
      }

      const sumForm = {
        "Order code": "Total",
        "Order type": "",
        "Date/Time": "",
        Store: "",
        Customer: "",
        Rider: "",
        Status: "",
        "Net price": reportList.value
          .reduce(
            (partial_sum, a) => partial_sum + parseFloat(a.order_net_price),
            0
          )
          .toString(),
      };

      data.push(sumForm);

      tsXLXS().exportAsExcelFile(data).saveAsExcelFile(`Order Report  ${moment(
        originalConfig.value.startDate
      ).format("YYYY/MM/DD")} -
                ${moment(originalConfig.value.endDate).format("YYYY/MM/DD")}`);
    }

    onMounted(() => {
      setCurrentPageTitle("Order Report");
    });

    return {
      moment,
      originalConfig,
      tableHeader,
      reportList,
      countOrderFoodComplete,
      countOrderMessengerComplete,
      countOrderFoodCancel,
      countMessengerFoodCancel,
      json_fields,
      json_data,
      exportReportToExcel,
    };
  },
});
